import { useState, type KeyboardEvent } from "react";
import "./tableTextFilter.css";

interface TableTextFilterProps {
    clear: () => void;
    onSubmit: (value: string) => void;
    placeholder: string;
    number?: boolean;
    value: string;
    onChange: (value: string) => void;
}

export function TableTextFilter({ clear, onSubmit, onChange, value, placeholder, number = false }: TableTextFilterProps) {
    const [inputValue, setInputValue] = useState(value);

    const sanitizeValue = (rawValue: string): string =>
        rawValue.replace(/[^a-zA-ZÀ-ž0-9\s\-–_.@]+/g, "").replace(/^\s+/, "");

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const isEnterKey = event.key === "Enter";

        if (isEnterKey) {
            if (inputValue) {
                onSubmit(sanitizeValue(inputValue));
                onChange(sanitizeValue(inputValue));
            }
            else {
                clear();
                onChange("");
            }

        }
    };

    const handleChange = (value: string) => {
        let newValue = value;

        if (number) {
            newValue = newValue.replace(/[^0-9]/g, '');
            setInputValue(newValue);
        }
        setInputValue(newValue);
    };

    const handleClear = () => {
        clear();
        onChange("");
    }

    return (
        <div className="searchInput-container">
            <input
                className="searchField"
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                type="text"
                pattern={number ? "\\d*" : undefined}
                value={inputValue}
                onChange={(e) => handleChange(sanitizeValue(e.target.value))}
            />
            <div className={`clearButton ${value ? "" : "inactive"}`} onClick={handleClear}>
                <span className="asideListSearch__clear-icon icon icon-light-cross icon icon-grey icon-new icon-12" />
            </div>
        </div>
    );
}
