import "admin/src/style/videoMetadataPage.css";
import { _ } from "i18n";
import client from "libs/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "ui-kit";

interface RetryModalProps {
    videoId: number;
    close: () => void;
}

export function DeleteModal({ videoId, close }: RetryModalProps) {
    const { addSuccessToast, addErrorToast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    const deleteMutation = client.deleteVideoUpload.useMutation({
        onSuccess: () => {
            addSuccessToast("Upload deleted");
            client.queryClient.invalidateQueries({
                predicate: (query) => query.queryKey.includes("metadataVideos"),
            });
            if (location.pathname !== "/video-uploads/errored") {
                navigate("/video-uploads/errored")
            }
        },
        onError: (res) => addErrorToast(res.message),
    });

    const onSubmit = () => {
        deleteMutation.mutate({ id: videoId })
    };

    return (
        <div className="approveVideoModal-container">
            <div className="approveMessage">
                The whole upload will be deleted and file will have to be uploaded again. Are you sure you want to delete the upload?
            </div>
            <div className="buttons">
                <div className="submit" onClick={() => onSubmit()}>
                    Delete
                </div>
                <div className="cancel" onClick={() => close()}>
                    Cancel
                </div>
            </div>
        </div>
    );
}
