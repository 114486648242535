import { Link } from "react-router-dom";
import { BaseTable } from "./BaseTable";
import { useState } from "react";
import Modal from "ui-kit/src/Modal";
import { ResetModal } from "../ResetModal";
import { RetryModal } from "../RetryModal";

export function VideosErroredTable() {
	const [retryModal, setRetryModal] = useState<{ id?: number, show: boolean }>({ show: false });
	const [resetModal, setResetModal] = useState<{ id?: number, show: boolean }>({ show: false });

	return (
		<>
			<BaseTable preset="errored"
				actions={{
					id: "actions",
					key: "actions",
					header: () => "Actions",
					cell: (value, row) => (
						<div className="actionsCell">
							<Link className="actionsButton" to={`/video-uploads/${row.id}/status`}>
								Status
							</Link>
							<div className="actionsButton" onClick={() => setRetryModal({ id: row.id, show: true })}>
								Retry
							</div>
							<div className="actionsButton" onClick={() => setResetModal({ id: row.id, show: true })}>
								Reset
							</div>
						</div>
					),
				}}
			/>
			<Modal show={resetModal.show} onClose={() => setResetModal({ show: false })} size="sm" title="Reset">
				<ResetModal videoId={resetModal.id ?? 0} close={() => setResetModal({ show: false })} />
			</Modal>
			<Modal show={retryModal.show} onClose={() => setRetryModal({ show: false })} size="sm" title="Retry">
				<RetryModal videoId={retryModal.id ?? 0} close={() => setRetryModal({ show: false })} />
			</Modal>
		</>
	)

}
