import Icon from "components/Icon";
import { _ } from "i18n";
import { MenuData } from "libs/apiClient";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import type { MenuItems } from "./functions";
interface SidebarProps {
	onMenuClick: (id: string) => void;
	activeMenu: string | null;
	menuItems: MenuItems;
}

const menuItemIds = Object.keys(MenuData._def.shape()).reduce(
	(prev, current) => {
		//@ts-ignore
		prev[current] = current;
		return prev;
	},
	{} as Record<keyof MenuData, keyof MenuData>,
);

export function Sidebar({ onMenuClick, activeMenu, menuItems }: SidebarProps) {
	const navLinks = useRef<HTMLUListElement>(null);
	const [expandMetadata, setExpandMetadata] = useState<boolean>(false);
	const [expandPlayerAccMgmt, setExpandPlayerAppMgmt] = useState<boolean>(false);

	const closeAllSubMenu = () => {
		navLinks.current?.childNodes.forEach((value: ChildNode, key: number) => {
			if (navLinks.current?.children.item(key)?.classList.contains("showMenu")) {
				navLinks.current?.children.item(key)?.classList.toggle("showMenu");
			}

			if (navLinks.current?.children.item(key)?.children[0].classList.contains("active")) {
				navLinks.current?.children.item(key)?.children[0].classList.toggle("active");
			}
		});
	};

	return (
		<div
			className="sidebar"
			onClick={(e) => {
				e.preventDefault();
				if (e.target === e.currentTarget) {
					e.currentTarget.classList.toggle("close");
				}
			}}
		>
			<div className="logo-details hide">
				<a href="#">
					<i className="bx-menu">
						<svg className="icon icon-admin-menu">
							<use xlinkHref="#icon-admin-menu" />
						</svg>
					</i>
				</a>
			</div>
			<ul className="nav-links" ref={navLinks}>
				{menuItems?.map((menuItem) => {
					if (menuItem.id === menuItemIds.player_account_management) {
						return (
							<li
								key={menuItem.id}
								className={`main-link hasSubMenu ${expandPlayerAccMgmt && "showMenu"}`}
								onClick={() => setExpandPlayerAppMgmt(!expandPlayerAccMgmt)}
							>
								<div className="icon-link">
									<a href="#">
										<i>
											<span className="icon icon-new icon-admin-grp-newicon icon-24" />
										</i>
										<span className="link_name">{menuItem.name}</span>
									</a>
									<i className="bx arrow">
										<span className="icon-new icon-24 icon-dark-lavendar icon-admin-arrow-new" />
									</i>
								</div>
								{expandPlayerAccMgmt && (
									<ul className="sub-menu">
										<li key="singupRequests">
											<Link to="/player-users/signup-requests">Sign-up Requests</Link>
										</li>
										<li key="playerUsers">
											<Link to="/player-users/player-user-accounts">Player Users</Link>
										</li>
									</ul>
								)}
							</li>
						);
					}
					if (menuItem.id === menuItemIds.game_uploads_management) {
						return (
							<li
								key={menuItem.id}
								className={`main-link hasSubMenu ${expandMetadata && "showMenu"}`}
								onClick={() => setExpandMetadata(!expandMetadata)}
							>
								<div className="icon-link">
									<a href="#">
										<i>
											<span className="icon icon-new icon-play icon-24" />
										</i>
										<span className="link_name">{menuItem.name}</span>
									</a>
									<i className="bx arrow">
										<span className="icon-new icon-24 icon-dark-lavendar icon-admin-arrow-new" />
									</i>
								</div>
								{expandMetadata && (
									<ul className="sub-menu">
										<li key="games">
											<Link to="/admin/videos">Games</Link>
										</li>
										<li key="approvals">
											<Link to="/video-uploads/approval">{_("admin.videoUploads.metadataEditor.ApprovalsVideos.label")}</Link>
										</li>
										<li key="in-processing">
											<Link to="/video-uploads/in-processing">In processing</Link>
										</li>
										<li key="finished">
											<Link to="/video-uploads/finished">Finished</Link>
										</li>
										<li key="errored">
											<Link to="/video-uploads/errored">Errored</Link>
										</li>
										<li key="all">
											<Link to="/video-uploads/all">All</Link>
										</li>
										<li key="video-test">
											<Link to="/video-test">{_("header.admin.video_test")}</Link>
										</li>
										<li key="monitoring">
											<Link to="/video-uploads/monitoring">Monitoring</Link>
										</li>
									</ul>
								)}
							</li>
						);
					}
					return (
						<li
							key={menuItem.id}
							className={
								(activeMenu === "non_partner_club" || activeMenu === "non_partner_player" || activeMenu === "migrations") &&
									menuItem.id === "non_partner_dataset"
									? "main-link hasSubMenu showMenu"
									: menuItem.hasSubMenu
										? "main-link hasSubMenu"
										: "main-link"
							}
							onClick={(e) => {
								closeAllSubMenu();
								if (e.currentTarget.classList.contains("hasSubMenu")) {
									e.currentTarget.classList.toggle("showMenu");
								} else {
									onMenuClick(menuItem.id);
								}
							}}
						>
							<div
								className={
									menuItem.id === "non_partner_dataset"
										? activeMenu === "non_partner_club"
											? "icon-link active"
											: "icon-link"
										: menuItem.id === activeMenu
											? "icon-link active"
											: "icon-link"
								}
							>
								<a href="#">
									<i>
										<Icon icon={menuItem.icon} size={24} />
									</i>
									<span className="link_name">{menuItem.name}</span>
								</a>
								{menuItem.hasSubMenu && (
									<i className="bx arrow">
										<span className="icon-new icon-24 icon-dark-lavendar icon-admin-arrow-new" />
									</i>
								)}
							</div>
							<>
								{menuItem.hasSubMenu && (
									<ul className="sub-menu">
										{menuItem.subMenuItems.map((subMenuItem, index) => {
											return (
												<li
													key={subMenuItem.id}
													id={subMenuItem.id}
													onClick={(e) => {
														closeAllSubMenu();
														onMenuClick(subMenuItem.id);
														e.currentTarget?.parentElement?.parentElement?.classList.toggle("showMenu");
													}}
												>
													<a href="#">{subMenuItem.name}</a>
												</li>
											);
										})}
									</ul>
								)}
							</>
						</li>
					);
				})}
			</ul>
		</div>
	);
}
