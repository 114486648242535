import { TableTextFilter } from "components/TableTextFilter/TableTextFilter";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { _ } from "i18n";
import type { PlayerUserRowResponse } from "libs/apiClient";
import client from "libs/client";
import { useEffect, useState } from "react";
import DatagridV2, { type SimpleColumn } from "ui-kit/src/Datagrid/DatagridV2";
import type { SelectValue } from "ui-kit/src/MultipleSelect/types";
import SelectComponent from "ui-kit/src/SelectComponent/SelectComponent";

export function PlayerUsersPage() {
    const [pagination, setPagination] = useState<{ pageIndex: number; pageSize: number }>({
        pageIndex: 0,
        pageSize: 15,
    });
    const [filters, setFilters] = useState<{ [key: string]: string | number | undefined }>();
    const [filterValues, setFilterValues] = useState<{ [key: string]: string | undefined }>({});
    const [sort, setSort] = useState<{ field: string, direction: string }>();
    const [selectedClub, setSelectedClub] = useState<SelectValue>();
    const [isActiveFilter, setIsActiveFilter] = useState<SelectValue>();
    const [tosFilter, setTosFilter] = useState<SelectValue>();

    const tosOptions = [{ value: "true", label: "Accepted" }, { value: "false", label: "Not Accepted" }]
    const statusOptions = [{ value: "true", label: "Active" }, { value: "false", label: "Inactive" }]

    useEffect(() => { setScrollBehavior() }, [])

    const { data, isLoading } = client.getPlayerUsers.useQuery({
        page: pagination.pageIndex + 1, limit: pagination.pageSize, filters: [
            ...(filters?.clubId ? [{
                field: "clubId",
                type: "exact",
                value: filters.clubId,
            }] : []),
            ...(filters?.firstName ? [{
                field: "firstName",
                type: "search",
                value: filters.firstName,
            }] : []),
            ...(filters?.lastName ? [{
                field: "lastName",
                type: "search",
                value: filters.lastName,
            }] : []),
            ...(filters?.email ? [{
                field: "email",
                type: "search",
                value: filters.email,
            }] : []),
            ...(filters?.phoneNumber ? [{
                field: "phoneNumber",
                type: "search",
                value: filters.phoneNumber,
            }] : []),
            ...(filters?.playerId ? [{
                field: "playerId",
                type: "exact",
                value: filters.playerId,
            }] : []),
            ...(filters?.acceptedTermsOfUse ? [{
                field: "acceptedTermsOfUse",
                type: "boolean",
                value: filters.acceptedTermsOfUse === "true",
            }] : []),
            ...(filters?.isActive ? [{
                field: "isActive",
                type: "boolean",
                value: filters.isActive === "true",
            }] : []),
        ],
        order: sort
    })

    const { data: clubs, isLoading: isClubsLoading } = client.getOptionsClubsOfPlayerUsers.useQuery({})

    const handleSetFilter = (key: string, value?: number | string,) => {
        setFilters({ ...filters, [key]: value });
        setPagination({ ...pagination, pageIndex: 0 });
    }

    const handleClearFilter = (key: string) => {
        setFilters({ ...filters, [key]: undefined })
    }

    const toggleSortOrder = (name: string) => {
        let newOrder: "ASC" | "DESC" | null = "DESC"

        if (sort?.field === name) {
            if (sort.direction === "DESC") {
                newOrder = "ASC"
            }
            if (sort.direction === "ASC") {
                newOrder = null
            }
        }

        if (newOrder !== null) {
            setSort({ field: name, direction: newOrder })
        } else {
            setSort(undefined)
        }

    };

    const generateSortArrows = (columnName: string) => (
        <div className="sortArrows" onClick={() => toggleSortOrder(columnName)}>
            {sort?.field === columnName ? (
                sort.direction === "ASC" ? (
                    <span className="icon-new icon-16 icon-select-arrow-down up" />
                ) : (
                    <span className="icon-new icon-16 icon-select-arrow-down down" />
                )
            ) : (
                <>
                    <span className="icon-new icon-16 icon-select-arrow-down up" />
                    <span className="icon-new icon-16 icon-select-arrow-down down" />
                </>
            )}
        </div>
    );

    const generateColumnHeader = (columnName: string, label: string, isNumber?: boolean) => () => (
        <div className="tableHeader">
            <TableTextFilter
                value={filterValues[columnName] || ""}
                onChange={(value) => setFilterValues({ ...filterValues, [columnName]: value })}
                number={isNumber}
                clear={() => {
                    handleClearFilter(columnName);
                    setFilterValues({ ...filterValues, [columnName]: undefined })
                }}
                onSubmit={(value: string) => handleSetFilter(columnName, value)}
                placeholder={label}
            />
            {generateSortArrows(columnName)}
        </div>
    );

    const simpleColumns: SimpleColumn<PlayerUserRowResponse>[] = [
        {
            id: "club",
            key: "clubId",
            header: () => (
                <div className="tableHeader">
                    <SelectComponent
                        value={selectedClub}
                        handleChange={(value) => {
                            setSelectedClub(value);
                            handleSetFilter("clubId", value?.value)
                        }}
                        placeholder="club"
                        items={clubs?.data}
                        isCleareable
                        isLoading={isClubsLoading}
                    />
                    {generateSortArrows("clubName")}
                </div>
            ),
            cell: (value, row) => row.clubName ?? "-",
        },
        {
            id: "firstName",
            key: "firstName",
            header: generateColumnHeader("firstName", "first name"),
            cell: (value, row) => row.firstName ?? "-",
        },
        {
            id: "lastName",
            key: "lastName",
            header: generateColumnHeader("lastName", "last name"),
            cell: (value, row) => <div className="thinCell">{row.lastName ?? "-"}</div>,
        },
        {
            id: "email",
            key: "email",
            header: generateColumnHeader("email", "email"),
            cell: (value, row) => <div className="thinCell">{row.email ?? "-"}</div>,
        },
        {
            id: "phoneNumber",
            key: "phoneNumber",
            header: generateColumnHeader("phoneNumber", "phone", true),
            cell: (value, row) => <div className="thinCell">{row.phoneNumber ?? "-"}</div>,
        },
        {
            id: "playerId",
            key: "playerId",
            header: generateColumnHeader("playerId", "player id"),
            cell: (value, row) => <div className="thinCell"><div>{row.playerId ? row.playerId : "-"}</div></div>,
        },
        {
            id: "acceptedTermsOfUse",
            key: "acceptedTermsOfUse",
            header: () => <SelectComponent
                value={tosFilter}
                handleChange={(value) => {
                    setTosFilter(value);
                    handleSetFilter("acceptedTermsOfUse", value?.value)
                }}
                placeholder="terms of use"
                items={tosOptions}
                isCleareable
            />,
            cell: (value, row) => <div className="thinCell"><div>{row.acceptedTermsOfUse ? "Accepted" : "Not Accepted"}</div></div>,
        },
        {
            id: "isActive",
            key: "isActive",
            header: () => <SelectComponent
                value={isActiveFilter}
                handleChange={(value) => {
                    setIsActiveFilter(value);
                    handleSetFilter("isActive", value?.value)
                }}
                placeholder="active"
                items={statusOptions}
                isCleareable
            />,
            cell: (value, row) => <div className="thinCell"><div>{row.isActive ? "Active" : "Inactive"}</div></div>,
        },
    ]

    return (
        <div className="signupRequestsPage-container">
            <div className="pageTitle"> Player User Accounts </div>
            <DatagridV2
                getRowLink={(row) => `/player-users/user/${row.id}/#playerDetails`}
                isLoading={isLoading}
                simpleColumns={simpleColumns}
                data={data?.data.items ?? []}
                pageCount={data?.data?.pages ?? 1}
                pagination={pagination}
                setPagination={setPagination}
                noResults={_("datatables.zero_records")} />
        </div>
    )
}