import Modal from "ui-kit/src/Modal";
import { BaseTable } from "./BaseTable";
import { useState } from "react";
import { ApprovalModal } from "../ApprovalModal";
import { Link } from "react-router-dom";
import { _ } from "i18n";

export function VideosApprovedTable() {
	const [approvalModal, setApprovalModal] = useState<{ id?: number, show: boolean }>({ show: false });

	return (
		<>
			<BaseTable preset="approved"
				actions={
					{
						id: "actions",
						key: "actions",
						header: () => "Actions",
						cell: (value, row) => (
							<div className="actionsCell">
								<Link className="actionsButton" to={`/video-uploads/${row.id}/status`}>
									Status
								</Link>
								<div
									className="actionsButton"
									onClick={() => {
										setApprovalModal({ show: true, id: row.id })
									}}
								>
									{_("admin.videoUploads.metadataEditor.btnRevoke.label")}
								</div>
							</div>
						),
					}
				}
			/>
			<Modal show={approvalModal.show} onClose={() => setApprovalModal({ show: false })} size="sm" title={_("admin.videoUploads.metadataEditor.modalRevokeTitle")}>
				<ApprovalModal videoId={approvalModal.id ?? 0} approved close={() => setApprovalModal({ show: false })} />
			</Modal>
		</>
	);
}
