import client from "libs/client";
import clsx from "clsx";
import { useRef, useState } from "react";
import { MediaPlayer, type MediaPlayerInstance, MediaProvider } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import type { GameData, VideoUploadDetail } from "libs/apiClient";
import { useToast } from "ui-kit";
import {
	convertFormValuesToTimelineValues,
	convertMetadataToValues,
	convertValuesToPeriods,
	parseTime,
	providersDropdownOptions,
	timeToSeconds,
	transformGamesData,
} from "./functions";
import { MetadataForm } from "./form";
import { type DefaultFormValues, type FormValues, MetadataSchema, type TimelineValues } from "./types";
import { Link } from "react-router-dom";
import Modal from "ui-kit/src/Modal";
import { ApprovalModal } from "features/videoUploads/ApprovalModal";
import { Timeline } from "./timeline";
import { VideoFact, VideoDetails } from "./details";
import { GameSelectComponent } from "features/videoUploads/GameSelectComponent";
import Select, { type SingleValue } from "react-select";
import { _ } from "i18n";

interface VideoMetadataEditorProps {
	video: VideoUploadDetail;
	gamesLoading: boolean;
	isGamesFetching: boolean;
	games: GameData[];
}

export function VideoMetadataEditor({ video, gamesLoading, isGamesFetching, games }: VideoMetadataEditorProps) {
	const { addSuccessToast, addErrorToast } = useToast();
	const [videoToApprove, setVideoToApprove] = useState<number>();
	const [showModal, setShowModal] = useState(false);
	const [videoProvider, setVideoProvider] = useState<SingleValue<{ value: string; label: string }>>();

	const clubsArray = transformGamesData(games);

	const metadata = MetadataSchema.nullable().parse(JSON.parse(video.metadata || "null"));
	let periods = {};
	if (metadata?.periods) {
		periods = convertMetadataToValues(metadata);
	}

	const [filledPeriods, setFilledPeriods] = useState<TimelineValues>(convertFormValuesToTimelineValues(periods) || {});
	const [currentTime, setCurrentTime] = useState(0);
	const [selectedGame, setSelectedGame] = useState(metadata?.gameId);

	const onGameSelect = (game: number) => {
		setSelectedGame(game);
	};

	const player = useRef<MediaPlayerInstance>(null);

	const setHalfTimes = client.addVideoMetadata.useMutation({
		onSuccess: () => {
			addSuccessToast("metadata updated");
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("metadataVideos"),
			});
		},
		onError: (res) => addErrorToast(res.message),
	});

	const onSubmit = (values: FormValues) => {
		if (selectedGame && video.id) {
			setHalfTimes.mutate({
				id: video.id,
				body: {
					gameId: selectedGame,
					periods: convertValuesToPeriods(values),
					provider: videoProvider?.value,
				},
			});
		} else {
			addErrorToast("please select a game");
		}
	};

	const defaults: DefaultFormValues = {
		posterTime: "00:00",
		...periods,
	};

	return (
		<div className="videoMetadataEditor">
			<div className="VideoMetadataHeading-container">
				<div className="VideoMetadataHeading">
					<h1> Name: {video.name}</h1>
					{video.uploadedBy ? <span className="videoMetadataUploadedBy">uploaded by: {video.uploadedBy}</span> : null}
				</div>
				<div className="BacktoVideoMetadata">
					<span className="icon-new icon-24 icon-dark-lavendar icon-admin-arrow-new rotate-180" />
					<Link to="/video-uploads/approval">Back to video list</Link>
				</div>
			</div>
			<div className="Videoplayer-container">
				<MediaPlayer
					ref={player}
					src={{ src: video.link || "", type: "video/mp4" }}
					onTimeUpdate={({ currentTime }) => {
						setCurrentTime(currentTime);
					}}
				>
					<MediaProvider />
					<DefaultVideoLayout icons={defaultLayoutIcons} />
				</MediaPlayer>
			</div>
			{video.duration && (
				<Timeline
					periods={filledPeriods}
					currentTime={currentTime}
					duration={timeToSeconds(parseTime(video.duration))}
					player={player.current}
				/>
			)}
			<VideoDetails>
				{video.createdAt && (
					<VideoFact
						label={`Uploaded ${video.uploadedBy ? `by ${video.uploadedBy}` : ''} at`}
						value={`${new Date(video.createdAt).toLocaleDateString()} ${new Date(video.createdAt).toLocaleTimeString()}`}
					/>
				)}
				{video.size && <VideoFact label="File Size" value={video.size} />}
				{video.duration && <VideoFact label="Duration" value={video.duration} />}
				{video.bitRate && <VideoFact label="Bitrate" value={video.bitRate} />}
				{video.width && video.height && <VideoFact label="Resolution" value={`${video.width}x${video.height}`} />}
			</VideoDetails>
			<div className="VideotimingDetails-container">
				<div className="gameProvider-container">
					<div className="videoProvider-container">
						<div>Select video provider</div>
						<Select options={providersDropdownOptions} isClearable value={videoProvider} onChange={setVideoProvider} />
					</div>{!gamesLoading && <GameSelectComponent isFetching={isGamesFetching} clubsArray={clubsArray} metadata={metadata} onGameSelect={onGameSelect} />}
				</div>
				<div className="VideotimingDetails">
					<MetadataForm onSubmit={onSubmit} player={player.current} defaults={defaults} onChange={setFilledPeriods} />
					<button
						disabled={video.metadata === "null"}
						type="button"
						className={clsx("videoMetadataEditor-approvalButton", { disabled: video.metadata === "null" })}
						onClick={() => {
							setVideoToApprove(video.id);
							setShowModal(true);
						}}
					>
						{video.metadataApprovedAt ? <>{_("admin.videoUploads.metadataEditor.modalRevokeTitle")}</> : <>{_("admin.videoUploads.metadataEditor.btnApprove.label")}</>}
					</button>
				</div>
			</div>
			<Modal show={showModal} onClose={() => setShowModal(false)} size="sm" title={video.metadataApprovedAt ? _("admin.videoUploads.metadataEditor.modalRevokeTitle") : _("admin.videoUploads.metadataEditor.modalApproveTitle")}>
				<ApprovalModal videoId={videoToApprove ?? 0} approved={!!video.metadataApprovedAt} close={() => setShowModal(false)} />
			</Modal>
		</div>
	);
}
