import Icon from "components/Icon";
import { _ } from "i18n";
import Select, { GroupBase, SingleValue } from "react-select";
import { useState, FC } from "react";
import userPlaceholder from "assets/images/user-placeholder.png";
import { MigrationType, NonPartnerMigrationPlayer } from "../../types";
import { addMigrationIdInLocalStorage } from "pages/productManagement/functions";
import { CountryCode, CountryFlag } from "ui-kit/src/flags";
import client from "libs/client";
interface PlayerMigrationItemProps {
	player: NonPartnerMigrationPlayer;
	setSourcePlayers: React.Dispatch<React.SetStateAction<NonPartnerMigrationPlayer[]>>;
	migrationType: string;
	teamList: ({ value: string; label: string } | GroupBase<{ value: string; label: string }>)[];
	index: number;
	countriesList: { [key: string]: string };
}

const PlayerMigrationItem: FC<PlayerMigrationItemProps> = ({ index, player, migrationType, teamList, setSourcePlayers, countriesList }) => {
	const publicUrl = new URL(`public`, import.meta.url);

	const [isloading, setIsloading] = useState(false);

	const onContinueClick = async (teamId: number | null, migrationId: number, playerId: number, playerIndex: number) => {
		const params = { body: { partnerMigrationId: migrationId, sourcePlayerId: playerId, teamId: teamId } };
		const apiClientQuery = migrationType === MigrationType.ANY ? client.playerMigrationToPartner : client.createPartnerPlayerFromNonpartner;
		const response = await apiClientQuery.mutation(params).then((res) => { return res?.data });

		if (response) {
			setIsloading(false);
		}

		if (response.percentage === 100) {
			setSourcePlayers((prev) => {
				if (prev) {
					prev[playerIndex].percentage = response.percentage;
					prev[playerIndex].status = response.status;
					return [...prev];
				}
				return prev;
			});

			addMigrationIdInLocalStorage(response.id);
		}
	};

	const onPlayerExistsActionClick = async (migrationId: number, status: string, targetPlayerId: number, playerIndex: number, teamId: number) => {
		setIsloading(true);

		try {
			const response = await client.playerMigrationConflict.mutation({
				body: { migrationId, status, targetPlayerId, teamId },
			});

			const playerActionResponse = response?.data;

			if (playerActionResponse) {
				setIsloading(false);

				setSourcePlayers((prev) => {
					if (prev) {
						prev[playerIndex].percentage = 75;
						return [...prev];
					}
					return prev;
				});
			}

			if (playerActionResponse && playerActionResponse.percentage === 100) {
				setSourcePlayers((prev) => {
					if (prev) {
						prev[playerIndex].percentage = playerActionResponse.percentage;
						prev[playerIndex].status = playerActionResponse.status;
						return [...prev];
					}
					return prev;
				});
				addMigrationIdInLocalStorage(playerActionResponse.id);
			}
		} catch (error) {
			setIsloading(false);
			setSourcePlayers((prev) => {
				if (prev) {
					prev[playerIndex].percentage = 50;
					prev[playerIndex].mergeError = error.message;
					return [...prev];
				}
				return prev;
			});

			return;
		}
	};

	return (
		<>
			<div className="card-row1">
				<div className="card-logo club-players">
					<img
						src={player.picture !== null ? (player.picture.includes("http") ? player.picture : `${publicUrl}/${player.picture}`) : userPlaceholder}
					/>
				</div>
				<div className="card-details">
					<h6>
						{player.firstName} {player.lastName}
					</h6>
					{player.clubCountry && (
						<div className="playerCardBlock__geo">
							<CountryFlag code={player.clubCountry as CountryCode} />
							<span> {countriesList[player?.clubCountry as keyof {}]}</span>
						</div>
					)}
				</div>
				{player.percentage === 50 && (
					<>
					<div className="migration-options">
						<button
							disabled={isloading}
							className="migrate-option-btn"
							onClick={() => onPlayerExistsActionClick(player.migrationId, "skipped", player.targetPlayerId, index)}
						>
							<Icon icon="migrate-skip-new" size={20} color="danger"/> {_("migration.btn.skip")}
						</button>
						<button
							disabled={isloading}
							className="migrate-option-btn"
							onClick={() => {
								onPlayerExistsActionClick(player.migrationId, "merged", player.targetPlayerId, index);
							}}
						>
							<Icon icon="migrate-merge-new" size={20} color="orange"/> {_("migration.btn.merge")}
						</button>
						<button
							disabled={isloading}
							className="migrate-option-btn"
							onClick={() => {
								if (migrationType === MigrationType.PARTNER) {
									setSourcePlayers((prev) => {
										if (prev) {
											prev[index].percentage = 70;
											prev[index].status = "retained";
											return [...prev];
										}
										return prev;
									});
								} else {
									onPlayerExistsActionClick(player.migrationId, "retained", player.targetPlayerId, index);
								}
							}}
						>
							<Icon icon="tick-new" size={20} color="success"/> {_("migration.btn.retain")}
						</button>
					</div>

					{player.mergeError && (
						<div className="card-row1">
							<div className="card-details">{player.mergeError}</div>
						</div>
					)}
					</>
					)}
					{(player.percentage === 70 || player.percentage === 80) && (
						<div className="migration-options">
							<Select
								className="react-select"
								options={teamList}
								placeholder={_("migration.placeholder.team")}
								value={
									player.selectedTeam
										? (player.selectedTeam as SingleValue<{
											value: string;
											label: string;
										}>)
										: null
								}
								onChange={(e) => {
									if (e !== null) {
										setSourcePlayers((prev) => {
											if (prev) {
												prev[index].selectedTeam = e;
												return [...prev];
											}
											return prev;
										});
									}
								}}
							/>
							<button
								disabled={isloading}
								className={"btn btn-light-blue continue"}
								onClick={() => {
									setIsloading(true);
									if (player.percentage === 70) {
										onPlayerExistsActionClick(
											player.migrationId,
											player.status,
											player.targetPlayerId,
											index,
											parseInt(player.selectedTeam ? player.selectedTeam.value : "")
										);
									} else {
										onContinueClick(
											player.selectedTeam ? parseInt(player.selectedTeam.value) : null,
											player.migrationId,
											player.id,
											index
										);
									}
								}}
							>
								{_("btn.continue")}
							</button>
						</div>
					)}
					</div>
					</>
					);
				};
				export default PlayerMigrationItem;
